<template>
    <div class="page">
        <app-header :navIndex="3"></app-header>
        <banner :data="banner"></banner>
        <section class="section-container mb-12">
            <section-title :title="team.title" :enTitle="team.enTitle"></section-title>
            <v-img class="mt-12 mb-12" :src="team.img"></v-img>
            <div class="d-flex flex-column flex-md-row mt-5 mt-md-12 team-box" v-for="(item,index) in team.items"
                 :key="index">
                <div class="col-md-3 pa-0">
                    <v-img contain :src="item.imgUrl"></v-img>
                </div>
                <div class="ml-md-12 pa-5 pa-md-0">
                    <div class="mt-md-12 item-name font-weight-bold">{{item.name}}</div>
                    <div class="mt-5 mb-5 mb-md-12 item-position">{{item.position}}</div>
                    <div class="desc" v-html="item.desc"></div>
                </div>
            </div>

        </section>
    </div>
</template>
<script>
    import AppHeader from "../../components/core/header/index";
    import Banner from "../../components/base/banner";
    export default {
        components: {
            Banner,
            AppHeader},
        data(){
            return {
                banner: {
                    imgUrl: 'company/about/bg_one.jpg',
                    title: this.$i18n.t('about.team.title'),
                    enTitle: '',
                },
                team: this.$i18n.t('about.team'),
            }
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "管理团队"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {}
    }
</script>
<style type="text/css" lang="scss" scoped>

</style>
